import en from './en.json'
import it from './it.json'
import ro from './ro.json'
import ru from './ru.json'
import uk from './uk.json'
import { useLanguageStore } from '~/store/language'

const store = useLanguageStore()

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'it',
  fallbackLocale: 'it',
  messages: {
    en,
    it,
    ro,
    ru,
    uk
  }
}))
